import { getDefaultOptions } from './auth';

interface GetOffersPayload {
  loanType: string;
  zipCode: string;
  propertyValue: number;
  loanAmount: number;
  loanTerms: [string];
  creditScore: number;
  propertyType: string;
  propertyUse: string;
  veteranStatus: string;
  pointsRange: string;
  includeFha: boolean;
  cashOut: string;
  nurla: object;
}

export const getOffers = async (payload: GetOffersPayload) => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/offers',
    { ...options, method: 'POST', body: payload },
  );
  return res;
};

interface UpdateOffersPayload {
  rvUrl: string;
  rvItem: string;
  resultsId: string;
}

export const updateOffers = async (payload: UpdateOffersPayload) => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/offers/update',
    { ...options, body: payload, method: 'PATCH' },
  );
  return res;
};
