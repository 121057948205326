import get from 'lodash/get';
import type { RouteLocationNormalized } from 'vue-router';
import { parseJwt } from '@/utils/jwt';
import { getToken, clearToken, validateSession, validateAuth } from '@/services';
import { useNurlaStore } from '@/store';

// ROUTES

const SESSION_NOT_ALLOWED = ['register', 'login'];

const SESSION_OPTIONAL = ['index', 'start', 'rates', 'rates-out', 'maintenance', 'coming-soon', 'not-found', 'thank-you', 'developer'];

const SESSION_REQUIRED = [
  'first-time',
  'whos-it-for',
  'when-are-you',
  'email',
  'name',
];

// STATES

const NO_SESSION = 'NO_SESSION';
const SESSION = 'SESSION';
const HAS_LOGGED_IN_BEFORE = 'HAS_LOGGED_IN_BEFORE';
const CURRENTLY_AUTHENTICATED = 'CURRENTLY_AUTHENTICATED';

export default defineNuxtRouteMiddleware(async (to:RouteLocationNormalized) => {
  const routeName = to.name as string;

  if (!routeName || SESSION_OPTIONAL.includes(routeName) || routeName.startsWith('ui')) return;

  let state = NO_SESSION;

  const token = getToken();
  if (token) {
    const { payload: { id, phone } } = parseJwt(token);

    if (id) {
      try {
        // Sessions must be signed and contain an ID in the token. They do not require a phone and
        // do not expire.
        await validateSession();
        useBugsnag().setUser(id);
        state = SESSION;

        if (phone) {
          state = HAS_LOGGED_IN_BEFORE;

          if (![...SESSION_OPTIONAL, ...SESSION_REQUIRED].includes(routeName)) {
            try {
              // Authenticated must be signed, contain an ID and phone in the token, and be within
              // the server's expiration time.
              await validateAuth();
              state = CURRENTLY_AUTHENTICATED;
              useNurlaStore().setUser({ loggedIn: true });
            } catch (e) {
              // Invalid auth. 401 is okay. Anything else is unexpected. If 401, don't clear tokens,
              // so that we can keep seeing Login, until logging in again.
              if (get(e, 'response.status') === 401) {
                useNurlaStore().setUser({ loggedIn: false });
              } else {
                throw e;
              }
            }
          }
        }
      } catch (e) {
        if (get(e, 'response.status') === 401) {
          useNurlaStore().resetState();
          clearToken();
        } else {
          throw e;
        }
      }
    }
  }

  // console.log({ state });

  if (SESSION_NOT_ALLOWED.includes(routeName)) {
    if ([CURRENTLY_AUTHENTICATED].includes(state)) {
      return navigateTo({ name: 'apply' });
    }

    if (['register'].includes(routeName)) {
      if ([HAS_LOGGED_IN_BEFORE].includes(state)) {
        return navigateTo({ name: 'login' });
      }
    }
  } else if (SESSION_REQUIRED.includes(routeName)) {
    if ([NO_SESSION].includes(state)) {
      return navigateTo({ name: 'start' });
    }
  } else {
    // All other routes require authentication, or else go to login
    if ([NO_SESSION, SESSION].includes(state)) {
      return navigateTo({ name: 'start' });
    } else if ([HAS_LOGGED_IN_BEFORE].includes(state)) {
      return navigateTo({ name: 'login' });
    }
  }
});
