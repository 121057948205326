import { createGtm } from '@gtm-support/vue-gtm';

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  nuxtApp.vueApp.use(createGtm({
    id: 'GTM-KCTGB6Z',
    defer: false,
    compatibility: false,
    enabled: config.public.env === 'production',
    debug: false,
    loadScript: true,
    // vueRouter: useRouter(),
    trackOnNextTick: false,
  }));
});
