function base64ToString (str:string) {
  if (import.meta.client) {
    return window.atob(str);
  }
  return Buffer.from(str, 'base64').toString('binary');
}

export function parseJwt (token:string) {
  const getDecodedJSON = (str:string) => {
    try {
      const result = JSON.parse(decodeURIComponent(escape(base64ToString(str))));
      return result;
    } catch (e) {
      return str;
    }
  };

  const encodedHeader = token.split('.')[0];
  const encodedPayload = token.split('.')[1];
  const encodedSignature = token.split('.')[2];
  return {
    header: getDecodedJSON(encodedHeader),
    payload: getDecodedJSON(encodedPayload),
    signature: getDecodedJSON(encodedSignature),
  };
}
