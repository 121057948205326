import numeral from 'numeral';

export function formatDollars (value: number) {
  if (value >= 0) {
    return '$' + numeral(value).format('9,999');
  }
  return '($' + numeral(Math.abs(value)).format('9,999') + ')';
}

export function formatRate (value: number) {
  if (value !== null) {
    return numeral(value).format('9.000');
  }
}

export function formatPoints (value: number) {
  if (value !== null) {
    return numeral(value).format('9.000');
  }
}

export function formatLongDate (value: string) {
  if (value) {
    const date = new Date(value);
    return date.toLocaleString('en-US', { timeZone: 'America/New_York', year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true, timeZoneName: 'short' });
  }
}
