import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';

export default defineNuxtPlugin((nuxtApp) => {
  const vuetify = createVuetify({
    ssr: true,
    components,
    directives,
    display: {
      mobileBreakpoint: 'md',
    },
    // theme: {
    //   defaultTheme: 'dark',
    // },
  });

  nuxtApp.vueApp.use(vuetify);
});
