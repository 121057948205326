import get from 'lodash/get';
import { wait } from './state';
import { useNurlaStore } from '@/store';

export const getToken = () => {
  const token = useCookie('hb_token');
  if (!token.value) {
    const legacyToken = useCookie('token');
    return legacyToken.value;
  }
  return token.value;
};

export const clearToken = () => {
  const token = useCookie('hb_token');
  token.value = null;
};

export const setToken = async (value:string) => {
  const token = useCookie('hb_token', {
    path: '/',
    maxAge: 60 * 60 * 24 * (365 / 2), // 6mos
  });
  token.value = value;
  // Getting the token immediately after setting returns any old value, so setting the token
  // requires time or an event loop tick or something. Waiting here makes it work as expected.
  await wait(1);
};

export const getDefaultOptions = async () => {
  const token = await getToken();
  const nurlaStore = useNurlaStore();
  const tenantId = get(nurlaStore, 'tenant.id', '');
  const options:{ headers: { Authorization:string, 'X-Tenant-Id'?:string } } = {
    headers: {
      Authorization: token ? `Bearer ${token}` : '',
      'X-Tenant-Id': tenantId,
    },
  };
  return options;
};

export const unauthorizedResponse = async (e:Error) => {
  if (get(e, 'response.status') === 401) {
    useNurlaStore().resetState();
    await clearToken();
    navigateTo({ name: 'credit-resume' });
    return true;
  }
  return false;
};

export const limiter = async () => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  await $fetch(config.public.apiBaseUrl + '/auth/limiter', { ...options });
};

export const validateSession = async () => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  await $fetch(config.public.apiBaseUrl + '/auth/validate-session', { ...options });
};

export const validateAuth = async () => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  await $fetch(config.public.apiBaseUrl + '/auth/validate-auth', { ...options });
};

// Creates "user-less" contact in backend
export const start = async () => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/auth/start',
    { ...options, method: 'POST' },
  );
  return get(res, 'accessToken');
};

interface SendCodePayload {
  phone: string;
}

interface VerifyCodePayload {
  phone: string;
  code: string;
}

export const sendCode = async (payload: SendCodePayload) => {
  const config = useRuntimeConfig();
  const res = await $fetch(
    config.public.apiBaseUrl + '/auth/send',
    { body: payload, method: 'POST' },
  );
  return res;
};

export const validateAndSendCode = async (payload: SendCodePayload) => {
  const config = useRuntimeConfig();
  const res = await $fetch(
    config.public.apiBaseUrl + '/auth/validate-and-send',
    { body: payload, method: 'POST' },
  );
  return res;
};

export const login = async (payload: VerifyCodePayload) => {
  const config = useRuntimeConfig();
  const options = await getDefaultOptions();
  const res = await $fetch(
    config.public.apiBaseUrl + '/auth/login',
    { ...options, body: payload, method: 'POST' },
  );
  return get(res, 'accessToken');
};
