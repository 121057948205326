<style lang="scss" scoped>
  .error-container {
    padding: 5rem 0 10rem;

    img {
      width: 200px;
    }
  }
</style>

<template>
  <NuxtLayout>
    <v-container class="h-100 d-flex flex-column">
      <div class="d-flex justify-center align-center flex-column flex-grow-1 error-container">
        <img :src="illustration" alt="illustration">
        <h2 class="text-center mb-4">
          Uh-oh! Something went wrong
        </h2>
        <p class="text-center">
          Please try <a class="text-decoration-none" @click.prevent="onClick">refreshing your page</a>, or contact our support team if the issue persists.
        </p>
      </div>
    </v-container>
  </NuxtLayout>
</template>

<script setup>
import illustration from '@/assets/images/art/quirky-mobile-phone-with-a-megaphone.svg';

useHead({
  title: 'Error',
});

function onClick () {
  location.reload();
}

</script>
