export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"robots","content":"noindex,nofollow"},{"name":"description","content":"Use Homebuyer.com: Start your mortgage application process now. Fast pre-approvals in minutes."},{"name":"format-detection","content":"telephone=no"},{"property":"og:locale","content":"en_US"},{"property":"og:type","content":"article"},{"property":"og:title","content":"Homebuyer.com Mortgage Pre-approval"},{"property":"og:url","content":"https://homebuyer.com/nurla"},{"property":"og:site_name","content":"Homebuyer.com"},{"property":"og:description","content":"Use Homebuyer.com: Start your mortgage application process now. Fast pre-approvals in minutes."},{"property":"og:updated_time","content":"2022-08-09T15:59:45-04:00"},{"property":"og:image","content":"https://homebuyer.com/wp-content/uploads/2022/04/homebuyer-illustation-1200px.jpeg"},{"property":"og:image:secure_url","content":"https://homebuyer.com/wp-content/uploads/2022/04/homebuyer-illustation-1200px.jpeg"},{"property":"og:image:width","content":"1200"},{"property":"og:image:height","content":"234"},{"property":"og:image:alt","content":"Homebuyer.com"},{"property":"og:image:type","content":"image/jpeg"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:title","content":"Homebuyer.com Mortgage Pre-approval"},{"name":"twitter:site","content":"@homebuyer"},{"name":"twitter:creator","content":"@homebuyer"},{"name":"twitter:image","content":"https://homebuyer.com/wp-content/uploads/2022/04/homebuyer-illustation-1200px.jpeg"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"stylesheet","type":"text/css","href":"https://cdn.jsdelivr.net/npm/@mdi/font@7.2.96/css/materialdesignicons.min.css"}],"style":[],"script":[],"noscript":[],"titleTemplate":"%s - Homebuyer.com","title":"Mortgage Pre-Approval","htmlAttrs":{"lang":"en-US"}}

export const appPageTransition = {"name":"fade","mode":"out-in"}

export const appLayoutTransition = {"name":"fade","mode":"out-in"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000